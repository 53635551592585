exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-iguru-immigration-js": () => import("./../../../src/pages/about-iguru-immigration.js" /* webpackChunkName: "component---src-pages-about-iguru-immigration-js" */),
  "component---src-pages-alberta-js": () => import("./../../../src/pages/alberta.js" /* webpackChunkName: "component---src-pages-alberta-js" */),
  "component---src-pages-atlantic-immigration-pilot-js": () => import("./../../../src/pages/atlantic-immigration-pilot.js" /* webpackChunkName: "component---src-pages-atlantic-immigration-pilot-js" */),
  "component---src-pages-australia-skilled-immigration-js": () => import("./../../../src/pages/australia-skilled-immigration.js" /* webpackChunkName: "component---src-pages-australia-skilled-immigration-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-british-columbia-js": () => import("./../../../src/pages/british-columbia.js" /* webpackChunkName: "component---src-pages-british-columbia-js" */),
  "component---src-pages-business-immigration-js": () => import("./../../../src/pages/business-immigration.js" /* webpackChunkName: "component---src-pages-business-immigration-js" */),
  "component---src-pages-calgary-blog-js": () => import("./../../../src/pages/calgary-blog.js" /* webpackChunkName: "component---src-pages-calgary-blog-js" */),
  "component---src-pages-canadian-experience-class-js": () => import("./../../../src/pages/canadian-experience-class.js" /* webpackChunkName: "component---src-pages-canadian-experience-class-js" */),
  "component---src-pages-event-index-js": () => import("./../../../src/pages/event/index.js" /* webpackChunkName: "component---src-pages-event-index-js" */),
  "component---src-pages-family-sponsorship-js": () => import("./../../../src/pages/family-sponsorship.js" /* webpackChunkName: "component---src-pages-family-sponsorship-js" */),
  "component---src-pages-federal-skilled-worker-js": () => import("./../../../src/pages/federal-skilled-worker.js" /* webpackChunkName: "component---src-pages-federal-skilled-worker-js" */),
  "component---src-pages-immigration-js": () => import("./../../../src/pages/immigration.js" /* webpackChunkName: "component---src-pages-immigration-js" */),
  "component---src-pages-manitoba-js": () => import("./../../../src/pages/manitoba.js" /* webpackChunkName: "component---src-pages-manitoba-js" */),
  "component---src-pages-melbourne-blog-js": () => import("./../../../src/pages/melbourne-blog.js" /* webpackChunkName: "component---src-pages-melbourne-blog-js" */),
  "component---src-pages-new-brunswick-js": () => import("./../../../src/pages/new-brunswick.js" /* webpackChunkName: "component---src-pages-new-brunswick-js" */),
  "component---src-pages-new-foundland-and-labrador-js": () => import("./../../../src/pages/new-foundland-and-labrador.js" /* webpackChunkName: "component---src-pages-new-foundland-and-labrador-js" */),
  "component---src-pages-northwest-territories-js": () => import("./../../../src/pages/northwest-territories.js" /* webpackChunkName: "component---src-pages-northwest-territories-js" */),
  "component---src-pages-nova-scotia-js": () => import("./../../../src/pages/nova-scotia.js" /* webpackChunkName: "component---src-pages-nova-scotia-js" */),
  "component---src-pages-ontario-js": () => import("./../../../src/pages/ontario.js" /* webpackChunkName: "component---src-pages-ontario-js" */),
  "component---src-pages-other-program-js": () => import("./../../../src/pages/other-program.js" /* webpackChunkName: "component---src-pages-other-program-js" */),
  "component---src-pages-prince-edward-island-js": () => import("./../../../src/pages/prince-edward-island.js" /* webpackChunkName: "component---src-pages-prince-edward-island-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quebec-js": () => import("./../../../src/pages/quebec.js" /* webpackChunkName: "component---src-pages-quebec-js" */),
  "component---src-pages-quebec-selected-skilled-workers-js": () => import("./../../../src/pages/quebec-selected-skilled-workers.js" /* webpackChunkName: "component---src-pages-quebec-selected-skilled-workers-js" */),
  "component---src-pages-saskatchewan-js": () => import("./../../../src/pages/saskatchewan.js" /* webpackChunkName: "component---src-pages-saskatchewan-js" */),
  "component---src-pages-startup-visa-js": () => import("./../../../src/pages/startup-visa.js" /* webpackChunkName: "component---src-pages-startup-visa-js" */),
  "component---src-pages-student-immigration-a-js": () => import("./../../../src/pages/student-immigration/a.js" /* webpackChunkName: "component---src-pages-student-immigration-a-js" */),
  "component---src-pages-student-immigration-c-js": () => import("./../../../src/pages/student-immigration/c.js" /* webpackChunkName: "component---src-pages-student-immigration-c-js" */),
  "component---src-pages-student-immigration-common-js": () => import("./../../../src/pages/student-immigration/common.js" /* webpackChunkName: "component---src-pages-student-immigration-common-js" */),
  "component---src-pages-student-immigration-germany-js": () => import("./../../../src/pages/student-immigration/germany.js" /* webpackChunkName: "component---src-pages-student-immigration-germany-js" */),
  "component---src-pages-student-immigration-new-zealand-js": () => import("./../../../src/pages/student-immigration/new-zealand.js" /* webpackChunkName: "component---src-pages-student-immigration-new-zealand-js" */),
  "component---src-pages-student-immigration-united-kingdom-js": () => import("./../../../src/pages/student-immigration/united-kingdom.js" /* webpackChunkName: "component---src-pages-student-immigration-united-kingdom-js" */),
  "component---src-pages-student-immigration-united-states-js": () => import("./../../../src/pages/student-immigration/united-states.js" /* webpackChunkName: "component---src-pages-student-immigration-united-states-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-support-ticket-js": () => import("./../../../src/pages/support-ticket.js" /* webpackChunkName: "component---src-pages-support-ticket-js" */),
  "component---src-pages-sydney-blog-js": () => import("./../../../src/pages/sydney-blog.js" /* webpackChunkName: "component---src-pages-sydney-blog-js" */),
  "component---src-pages-toronto-blog-js": () => import("./../../../src/pages/toronto-blog.js" /* webpackChunkName: "component---src-pages-toronto-blog-js" */),
  "component---src-pages-vancouver-blog-js": () => import("./../../../src/pages/vancouver-blog.js" /* webpackChunkName: "component---src-pages-vancouver-blog-js" */),
  "component---src-pages-yukon-js": () => import("./../../../src/pages/yukon.js" /* webpackChunkName: "component---src-pages-yukon-js" */),
  "component---src-templates-australia-business-immigration-js": () => import("./../../../src/templates/australia-business-immigration.js" /* webpackChunkName: "component---src-templates-australia-business-immigration-js" */),
  "component---src-templates-australia-eligibility-calculator-js": () => import("./../../../src/templates/australia-eligibility-calculator.js" /* webpackChunkName: "component---src-templates-australia-eligibility-calculator-js" */),
  "component---src-templates-australia-pr-permanent-residency-js": () => import("./../../../src/templates/australia-pr-permanent-residency.js" /* webpackChunkName: "component---src-templates-australia-pr-permanent-residency-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-canada-business-immigration-js": () => import("./../../../src/templates/canada-business-immigration.js" /* webpackChunkName: "component---src-templates-canada-business-immigration-js" */),
  "component---src-templates-canada-eligibility-calculator-js": () => import("./../../../src/templates/canada-eligibility-calculator.js" /* webpackChunkName: "component---src-templates-canada-eligibility-calculator-js" */),
  "component---src-templates-canada-express-entry-program-js": () => import("./../../../src/templates/canada-express-entry-program.js" /* webpackChunkName: "component---src-templates-canada-express-entry-program-js" */),
  "component---src-templates-canada-pnp-program-js": () => import("./../../../src/templates/canada-pnp-program.js" /* webpackChunkName: "component---src-templates-canada-pnp-program-js" */),
  "component---src-templates-canada-pr-permanent-residency-js": () => import("./../../../src/templates/canada-pr-permanent-residency.js" /* webpackChunkName: "component---src-templates-canada-pr-permanent-residency-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-eligibility-criteria-js": () => import("./../../../src/templates/eligibility-criteria.js" /* webpackChunkName: "component---src-templates-eligibility-criteria-js" */),
  "component---src-templates-ielts-js": () => import("./../../../src/templates/ielts.js" /* webpackChunkName: "component---src-templates-ielts-js" */),
  "component---src-templates-iguru-event-js": () => import("./../../../src/templates/iguru-event.js" /* webpackChunkName: "component---src-templates-iguru-event-js" */),
  "component---src-templates-iguru-overseas-js": () => import("./../../../src/templates/iguru-overseas.js" /* webpackChunkName: "component---src-templates-iguru-overseas-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-language-ability-test-js": () => import("./../../../src/templates/language-ability-test.js" /* webpackChunkName: "component---src-templates-language-ability-test-js" */),
  "component---src-templates-pte-js": () => import("./../../../src/templates/pte.js" /* webpackChunkName: "component---src-templates-pte-js" */),
  "component---src-templates-student-immigration-australia-js": () => import("./../../../src/templates/student-immigration/australia.js" /* webpackChunkName: "component---src-templates-student-immigration-australia-js" */),
  "component---src-templates-student-immigration-canada-js": () => import("./../../../src/templates/student-immigration/canada.js" /* webpackChunkName: "component---src-templates-student-immigration-canada-js" */),
  "component---src-templates-student-loan-study-abroad-js": () => import("./../../../src/templates/student-loan-study-abroad.js" /* webpackChunkName: "component---src-templates-student-loan-study-abroad-js" */)
}

